// extracted by mini-css-extract-plugin
export var alternate = "article-preview-module--alternate--c7d57";
export var contentText = "article-preview-module--content-text--b44d6";
export var grid = "article-preview-module--grid--f8398";
export var image = "article-preview-module--image--35123";
export var imageRatio = "article-preview-module--image-ratio--54883";
export var noTitle = "article-preview-module--no-title--f81f7";
export var section = "article-preview-module--section--58e95";
export var subtitleText = "article-preview-module--subtitle-text--f5914";
export var titleText = "article-preview-module--title-text--59d67";
export var withImage = "article-preview-module--with-image--2795a";